@import "../css/global.scss";

.landingContainer {
  background-image: url(https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/xp_homepage_12_bg-1-min.webp);
  background-size: cover;
}




.logo {
  width: 10.3125rem;
  height: 4.125rem;
  position: relative;
}



.heroEyebrow {
  @include body-m-bold;
  color: $primary-beige;
  text-shadow: 0px 0px 50px rgba(133, 133, 133, 0.5);
  text-transform: uppercase;
  text-align: center;
}

.hero {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 12rem 0 8rem 0;

  @media (max-width: 960px) {
    padding: 8rem 1rem 5rem 1rem;
  }
  h1 {
    @include display-xl;
    color: $primary-beige;
    font-size: 7rem;
    margin: 0;
    text-shadow: 0px 0px 64px $primary-beige;
    text-transform: uppercase;
    text-align: center;

    @media (max-width: 960px) {
      @include header-m;
    }
  }

  .bigLogo {
    margin-top: -3rem;
    margin-bottom: -1rem;
    width: 22.5rem;
    height: 13.5rem;
    position:relative;

    @media (max-width: 480px) {
      width: 12.375rem;
      height: 5rem;
      margin-top: -1rem;
      margin-bottom: 0;
    }
  }

  .down {
    width: 2.5rem;
    height: 2.5rem;
    path {
      fill: $primary-beige;
    }
  }

  p {
    width: 80%;
  }
}

.heroSubtitle {
  @include body-s;
  color: $primary-beige;
  margin: 0;
  margin-bottom: 1rem;
  text-shadow: 0px 0px 50px rgba(133, 133, 133, 0.5);
  text-align: center;
}

.outlineButton {
  align-items: center;
  border: 1px solid $primary-beige;
  border-radius: 0.5rem;
  color: $primary-beige;
  cursor: pointer;
  display: flex;
  @include body-s-bold;
  margin-top: 2rem;
  margin-bottom: 4rem;
  padding: 0.5rem 2rem;

  &:hover {
    background: $glass-background;
  }

  .logo {
    width: 4rem;
    height: 2rem;
    margin: 0;
    margin-right: 0.5rem;
  }
}

.bodyContent {
  padding-bottom: 10rem;
}

:global(.sticky) + .bodyContentContainer {
  padding-top: 75px;
}

.carouselSection {
  margin-bottom: 10rem;
  h2 {
    color: $primary-beige;
    @include header-l;
    margin-bottom: 1rem;
    text-shadow: 0px 0px 64px #ffe9d2;
    text-transform: uppercase;
    text-align: center;

    @media (max-width: 960px) {
      @include header-m;
    }
  }
  .sectionSubtitle {
    color: $primary-beige;
    @include body-m;
    padding: 0 1rem;
    text-shadow: 0px 0px 50px rgba(133, 133, 133, 0.5);
    text-align: center;

    @media (max-width: 960px) {
      @include body-s;
      margin-bottom: 3rem;
    }

    span {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.carousel1 {
  margin-left: -10rem;
}

.carousel2 {
  margin-left: -30rem;
}

.carousel3 {
  margin-left: -15rem;
}

.cityTile {
  background: $glass-background;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
    0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(12.5px);
  cursor: pointer;
  @include stroke-border;
  display: inline-block;
  width: 33.25rem;
  height: 306.5px;
  position: relative;
  top: 4.3rem;

  @media (max-width: 460px) {
    margin-bottom: 5rem;
    margin-left: 1rem;
    height: 12.5rem;
    width: calc(100% - 2rem);
  }

  &:hover {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 8px;
      border: 2px solid transparent;
      background: $glass-stroke border-box;
      -webkit-mask: linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: destination-out;
      mask-composite: exclude;
      z-index: 1;
      transition: 0.1s all;
    }
  }

  .cityImage {
    position: absolute;
    right: 2rem;
    top: 2rem;
  }

  .cityContent {
    position: absolute;
    bottom: 2rem;
    left: 2rem;
  }

  .cityDescription {
    color: $primary-beige;
    @include body-l-bold;
    text-transform: uppercase;
    text-shadow: 0px 0px 50px rgba(67, 186, 125, 0.5);

    @media (max-width: 460px) {
      @include body-s-bold;
    }
  }

  .cityName {
    border-bottom: 1px solid $primary-beige;
    color: $primary-beige;
    @include header-xl;
    margin-top: 1rem;
    text-shadow: 0px 0px 64px #ffe9d2;
    text-transform: uppercase;

    @media (max-width: 460px) {
      @include header-m;

      img {
        height: 1.125rem;
      }
    }
  }
}

.section {
  h1 {
    color: $primary-beige;
    @include display-l;
    margin: 0 auto;
    text-shadow: 0px 0px 50px rgba(133, 133, 133, 0.5);
    text-transform: uppercase;
    text-align: center;
    width: 50%;

    @media (max-width: 960px) {
      @include header-m;
      width: 80%;
    }
  }

  .sectionSubtitle {
    color: $primary-beige;
    @include body-m-bold;
    text-shadow: 0px 0px 50px rgba(133, 133, 133, 0.5);
    text-transform: uppercase;
    text-align: center;

    @media (max-width: 960px) {
      @include body-s-bold;
      width: 75%;
      margin: 1rem auto;
    }
  }
}

.sectionContainer {
  align-items: center;
  @include glass-background;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 15rem auto 0 auto;
  width: 76rem;
  max-width: 80%;

  @media (max-width: 460px) {
    margin: 7rem auto 3rem auto;
    width: 100%;
  }

  .outlineButton {
    margin-bottom: 2rem;
  }
}

.gallery {
  margin-top: -15rem;
  width: 80%;

  @media (max-width: 460px) {
    margin-top: -5rem;
  }
}

.sectionContent {
  display: flex;

  @media (max-width: 960px) {
    flex-direction: column;
    padding: 0 1rem;
    margin-top: -1rem;
  }
}

.sectionColumn {
  padding: 2rem;
  margin-top: -5rem;

  @media (max-width: 960px) {
    margin-top: 0;
    padding: 2rem 1rem;
  }

  h3 {
    color: $primary-beige;
    @include header-m;
    text-shadow: 0px 0px 64px #ffe9d2;
    text-transform: uppercase;

    @media (max-width: 960px) {
      @include header-s;
      margin-top: 0;
    }
  }

  p {
    color: $white;
    @include body-s;
  }
}

.sectionRight {
  border-left: 1px solid $neutral-white-alpha-30;

  @media (max-width: 960px) {
    border-left: none;
    border-top: 1px solid $neutral-white-alpha-30;
  }
}

.tensor{
  margin-top:104px;
  margin-bottom:104px;
  height:150px;
  display: flex;
  width: 1224px;;
  max-width:80%;
  padding: var(--spacing-40, 40px) var(--spacing-64, 64px);
  border-radius: var(--spacing-8, 8px);
  background: var(--glass-background, linear-gradient(137deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.01) 100%), rgba(0, 0, 0, 0.25));
  /* Glass/Glass + Shadow */
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(12.5px);
  display:flex;
  align-items:center;
  justify-content:space-between;

  @media (max-width: 960px) {
    width: calc(100% - 2rem);
    height: fit-content;
    flex-direction: column-reverse;
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding: 1rem;
  }

  img {
    width: 30rem;
    @media (max-width: 960px) {
      margin-bottom: 1rem;
      width: 100%;
    }
  }

  :global(.wallet-adapter-button-trigger){
    color: var(--neutral-black, #181A1E) !important;
    text-align: center !important;
    font-family: Roboto Mono !important;
    font-size: 13px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: normal !important;
    display: flex;
    padding: var(--spacing-12, 12px) var(--spacing-16, 16px);
    justify-content: center;
    align-items: center;
    gap: var(--spacing-12, 12px);
    border-radius: var(--spacing-8, 8px);
    background: var(--secondary-blue, #19B2E6) !important;
    width:220px !important;

    @media (max-width: 960px) {
      width: 100% !important;
    }
  }

}

.tensorLeftTitle{
  color: var(--primary-beige, #FFE9D2);
  /* Header/S */
  font-family: Sequel100Black-85;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 25px */
  text-transform: uppercase;
  margin-bottom:8px;

  @media (max-width: 960px) {
    width:70vw;
  }
}

.tensorLeftText{
  width:500px;
  color: var(--neutral-white, #FAFAFA);
  /* Body/Regular/S */
  font-family: Roboto Mono;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom:24px;

  @media (max-width: 960px) {
    width:70vw;
  }
}
