@import "../../css/global.scss";

.headerRight {
  display: flex;
  align-items: center;

  :global(.wallet-adapter-button-trigger),
  .phoneButton {
    @include body-s-bold;
    background: unset !important;
    background-color: unset !important;
    box-shadow: unset;
    border-radius: 0 8px 8px 0;
    border-left: 1px solid $gray-secondary;
    font-family: Roboto Mono !important;
    font-size: 13px !important;
    font-weight: 700 !important;
    height: 41px;
    min-width: 153px;

    &:hover {
      &:before {
        border-radius: 0 8px 8px 0;
        border: 1px solid transparent;
      }
    }

    @media (max-width: 768px) {
      min-width: unset;
      padding: 0.5rem 1rem;
      svg {
        margin-right: 0;
      }
    }
  }
}

.phoneButton {
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    @include stroke-border;
    border-radius: 0 8px 8px 0;
  }
}

.blackHeader {
  align-items: center;
  color: $white;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 2rem);
  margin: 0 auto;
  padding: 1rem;
  position: relative;
  z-index: 2;

  @media (max-width: 960px) {
    background: $glass-background;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
      0px 4px 8px 3px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(12.5px);
  }
}

.headerInner {
  align-items: center;
  display: flex;
  justify-content: space-between;
  max-width: 1250px;
  margin: 0 auto;
  width: 100%;
}

.headerLink {
  align-items: center;
  box-sizing: border-box;
  border-bottom: 2px solid transparent;
  color: $secondary-gray-white;
  cursor: pointer;
  display: flex;
  @include body-s-bold;
  height: 43px;
  margin-right: 1.5rem;
  padding: 0.5rem;
  text-decoration: none;
  transition: 0.3s all;
  z-index: 1;

  svg {
    height: 18px;
    width: 18px;
    margin-right: 0.25rem;

    path {
      fill: #f5f6f6;
    }
  }

  &:hover {
    color: $secondary-gray-white;
    border-bottom: 2px solid $secondary-gray-white;
  }

  @media (max-width: 960px) {
    display: none;
  }
}

.discord {
  svg {
    margin-left: 0.5rem;
  }
  
  @media (max-width: 960px) {
    display: none;
  }
}

.headerLogo {
  height: 32px;
  width: 80px;
  z-index: 1;

  @media (max-width: 960px) {
    width: 5rem;
    height: 2rem;
  }
}

.selected,
.selected:hover {
  color: $secondary-gray-white;
  border: unset;

  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background: $secondary-gray-white;
    bottom: 0px;
    left: 0;
  }
}

.signInButton {
  width: fit-content;
  min-width: 152px;
}

.loggedInButtonContainer {
  align-items: center;
  background: $glass-background;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
    0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(12.5px);
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  position: relative;
  z-index: 1;
  @include stroke-border;
}

.tickets,
.wallet {
  width: 16px;
  height: 16px;
  path {
    fill: $secondary-blue;
  }
}

.ticketCount {
  align-items: center;
  color: $white;
  display: flex;
  height: 41px;
  justify-content: center;
  padding: 0 0.5rem;
  text-decoration: none;
  position: relative;
  z-index: 1;
  @include body-s-bold;

  svg {
    margin-right: 0.5rem;
  }

  &:hover {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 8px 0 0 8px;
      border: 2px solid transparent;
      background: $glass-stroke border-box;
      -webkit-mask: linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: destination-out;
      mask-composite: exclude;
      transition: 0.3s all;
    }
  }
}

.walletLoading {
  width: 17px;
  height: 17px;
}

.velvetHeader {
  align-items: center;
  color: $white;
  display: flex;
  justify-content: space-between;
  padding: 0 calc((100% - 1250px) / 2);

  margin: 0 auto;
  padding: 1rem;
  position: relative;
  width: calc(100% - 2rem);
  z-index: 2;

  @media (max-width: 960px) {
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
      0px 4px 8px 3px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(12.5px);
  }
  background: $neutral-black-alpha-50;
}

:global(.sticky) {
  position: fixed;
  top: 0;
  width: calc(100% - 2rem);
}
