@import "../css/global.scss";

.container {
  align-items: center;
  @include glass-background;
  @include stroke-border;
  border-radius: 8px;
  color: $secondary-gray-white;
  display: flex;
  @include body-s;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 2rem;
  padding: 2rem 1rem;
}

.iconContainer {
  align-items: center;
  @include glass-background;
  @include stroke-border;
  display: flex;
  justify-content: center;
  height: 64px;
  min-height: 64px;
  min-width: 64px;
  width: 64px;
}

.new {
  border-top: 1px solid $neutral-black-alpha-30;
  padding: 1rem;
  margin: 1rem auto 0 auto;
  text-align: center;
  width: calc(100% - 2rem);
  h2 {
    color: $white;
    @include header-xs;
    margin-bottom: 0;
    text-transform: uppercase;

    @media (max-width: 960px) {
      @include body-m-bold;
      text-transform: unset;
    }
  }
  p {
    margin-top: 0.5rem;
  }
}

.tileContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;

  svg {
    min-width: 24px;
    min-height: 24px;
    margin: auto 1rem;
  }

  @media (max-width: 960px) {
    flex-direction: column;
    width: calc(100% - 2rem);
  }
}

.arrowRight {
  @media (max-width: 960px) {
    display: none;
  }
}

.tile {
  align-items: center;
  @include glass-background;
  backdrop-filter: blur(50px);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 2rem;
  position: relative;
  text-align: left;
  width: 456px;
  z-index: 2;

  @media (max-width: 960px) {
    width: calc(100% - 2rem);
    margin-bottom: 1rem;
  }

  .tileTop {
    align-items: center;
    display: flex;
    margin-bottom: 1rem;
  }

  .iconContainer {
    margin-right: 1rem;
  }

  a {
    text-decoration: none;
    width: 100%;
  }

  button {
    cursor: pointer;
    padding: 1rem 2rem;
    position: relative;
  }

  h3 {
    color: $secondary-gray-white;
    @include body-s-bold;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

  p {
    @include body-xs;
    margin: 0;
  }
}
