@import '../css/global.scss';

.goldModal {
    height: 581px !important;
    @media (max-width: 960px) {
        margin-top: 1rem;
    }
}

.container {
    background: $neutral-black;
    @include stroke-border;

    img {
        border-radius: 8px;
        width: 100%;
        height: 100%;
    }

    .imagePlaceholder {
        width: 392px;
        height: 392px;
    }

    h1 {
        color: $primary-beige;
        @include header-xs;
        text-transform: uppercase;
        text-align: center;

    }

    p {
        color: $neutral-gray;
        @include body-s;
    }
}

.content {
    padding: 1rem 1rem 2rem 1rem;
}

.buttonContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;

    .button {
        width: calc(50% - 0.5rem);
    }

    a {
        text-decoration: none;
    }
}