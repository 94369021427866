@import "../../css/global.scss";

.button {
  @include body-s-bold;

  align-items: center;
  background: linear-gradient(
    249.51deg,
    rgba(0, 255, 163, 0.25) -47.16%,
    rgba(220, 31, 255, 0.25) 140.55%
  );
  border: 1px solid transparent;
  border-radius: 12px;
  color: $white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 41px;
  padding: 1rem;
  text-align: center;
  transition: 0.3s all;
  width: fit-content;

  position: relative;
  z-index: 1;

  &:hover {
    &::before {
      border: unset;
    }
  }

  &:disabled {
    background: $glass-disabled;
    border: unset;
    color: $neutral-gray;
    cursor: not-allowed;

    &::before {
      border: unset;
    }

    svg {
      path {
        fill: $neutral-gray;
      }
    }

    &:hover {
      border: unset;
    }
  }
}

.gradient {
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 12px;
    border: 2px solid transparent;
    background: $ticketdex-gradient border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
    transition: 0.3s all;
  }
}

.fullWidth {
  display: flex;
  justify-content: center;
  width: 100%;
}

.compact {
  align-items: center;
  display: flex;
  font-size: 16px;
  height: fit-content;
  padding: 0.5rem 1rem;
  width: fit-content;
}

.leftIcon {
  align-items: center;
  display: flex;
  margin-right: 0.5rem;
}

.rightIcon {
  align-items: center;
  display: flex;
  margin-left: 0.5rem;
}

.gray {
  background: $glass-background;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
    0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(12.5px);
  border: unset;
  box-sizing: border-box;
  border-radius: 8px;
  height: 41px;
  text-shadow: 0px 0px 50px 4px rgba(67, 186, 125, 0.5);

  &:hover {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 8px;
      border: 2px solid transparent;
      background: $glass-stroke border-box;
      -webkit-mask: linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: destination-out;
      mask-composite: exclude;
      z-index: 1;
      transition: 0.3s all;
    }
  }

  &::before,
  &:disabled::before {
    border: unset;
  }
}

.blue {
  border: 1px solid $secondary-blue;
  border-radius: $s;
  background: $secondary-blue;
  color: $neutral-black;
  padding: $m $xxl;
  text-decoration: none;

  @include body-s-bold;

  svg {
    path {
      fill: $neutral-black;
      transition: 0.1s all;
    }
  }

  &::before {
    border: unset;
  }

  &:hover {
    border: 1px solid $secondary-blue;
    background-color: $secondary-blue-30;
    color: $secondary-blue;
    box-sizing: border-box;
    transition: 0.1s all;

    svg {
      path {
        fill: $secondary-blue;
        transition: 0.1s all;
      }
    }
  }

  &:disabled {
    &:hover {
      background: $glass-disabled;
      border: unset;
      color: $neutral-gray;
      cursor: not-allowed;
    }
  }
}

.beige {
  border: 1px solid var(--primary-beige, #ffe9d2);
  background: var(--primary-beige, #ffe9d2);
  box-shadow: 0px 0px 12px #ffe9d2;
  color: $neutral-black;
  font-family: Sequel100Black-85;
  text-transform: uppercase;
}

.beigeLight {
  border: 1px solid var(--primary-beige, #ffe9d2);
  background: var(--primary-beige, #ffe9d2);
  color: $neutral-black;
}

.outline {
  border: 1px solid $white;
}

.rounded {
  border-radius: 40px;

  &::before {
    border-radius: 40px !important;
  }
}


.beigeOutline {
  border: 1px solid $primary-beige;
  background: transparent;
  border-radius: 8px;
  color: $primary-beige;

}