@import "../css/global.scss";

.unsealModalContainer {
  background: $ticketdex-non-opaque-background;
  border-radius: 8px;
  padding: 1rem;

  @include stroke-border;
}

.close {
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 1;
}

.warningIcon {
  font-size: 40px;
  text-align: left;
  margin-bottom: 1rem;
  width: 100%;
}

.unsealWarning {
  color: $primary-beige;
  @include header-xs;
  margin-bottom: 1rem;
}

.unsealed {
  color: $system-green-green;
}

.subtext {
  color: $neutral-gray;
  @include body-s;
  margin-bottom: 2rem;
}

.warningListContainer {
  background: $neutral-input-box;
  border-radius: 8px;
  margin-bottom: 2rem;
  padding: 1rem 1rem 0.5rem 1rem;
  width: calc(100% - 2rem);

  @include stroke-border;
}

.warningListItem {
  align-items: center;
  color: $white;
  display: flex;
  @include body-s;
  margin-bottom: 1.5rem;

  &:last-of-type {
    margin-bottom: 1rem;
  }
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;

  button {
    width: calc(50% - 0.5rem);
  }
}
