@import "../../css/global.scss";

.input {
  background: $neutral-input-box;
  border: 1px solid transparent;
  border-radius: 8px;
  box-sizing: border-box;
  color: #9c9da1;
  @include body-s-bold;
  height: 51px;
  letter-spacing: 0.05em;
  margin-bottom: 1rem;
  padding: 1rem;
  transition: all 0.3s;

  @include stroke-border;

  &:focus {
    outline: none;
  }

  &:hover,
  &:active,
  &:focus {
    background: linear-gradient(rgba(10, 9.9, 1), rgba(10, 9, 9, 1)) padding-box,
      $white border-box;
    border: 2px solid transparent;
    // keep element from moving on hover but without having to use a fixed width
    margin-left: -1px;
    margin-right: -1px;
  }
}
