.loadingContainer {
  position: relative;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 99;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15rem 0;

  img {
    width: 100px;
  }
}
