@import "../css/global.scss";

.container {
  background: $ticketdex-non-opaque-background;
  border-radius: 8px;
  padding: 1rem;

  @include stroke-border;
}

.close {
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 1;
}

.icon {
  font-size: 40px;
  text-align: left;
  margin-bottom: 1rem;
  width: 100%;
}

.header {
  color: $system-green-green;
  @include header-xs;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

.infoContainer {
  color: $white;
  background: $neutral-input-box;
  border-radius: 8px;
  margin-bottom: 2rem;
  padding: 1rem;
  width: calc(100% - 2rem);

  @include stroke-border;
  @include body-s;
}

.subtext {
  color: $neutral-gray;
  @include body-s;
  margin-bottom: 2rem;
}
