@import "../css/global.scss";

.contentContainer {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 1100px;

  @media (max-width: 960px) {
    flex-direction: column;
    width: 100%;
  }
}

.contentRight {
  width: calc(55% - 0.5rem);

  @media (max-width: 960px) {
    width: 100%;
  }
}

.contentLeft {
  width: calc(45% - 0.5rem);

  @media (max-width: 960px) {
    width: 100%;
  }
}

.imageContainer {
  @include glass-background;
  @include stroke-border;
  padding: 1rem;
  margin-bottom: 1rem;

  img {
    @include stroke-border;
    border-radius: 8px;
    width: 100%;
    height: auto;
  }

  @media (max-width: 960px) {
    display: none;
  }
}

.container {
  @include glass-background;
  @include stroke-border;
  border-radius: 8px;
  margin-bottom: 1rem;
  padding: 1rem;
}

.venueInfoContainer {
  display: flex;
  justify-content: space-between;
}

.title {
  color: $primary-beige;
  @include body-xl-bold;
  margin-bottom: 0.5rem;
}

.date {
  color: $secondary-blue;
  @include body-s-bold;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
}

.venue {
  align-items: center;
  color: $secondary-gray-white;
  display: flex;
  @include body-s;

  svg {
    margin-right: 0.5rem;
    min-width: 18px;
    min-height: 18px;

    path {
      fill: $secondary-gray-white;
    }
  }
}

.info {
  padding: 0.5rem 0.75rem;
}

.priceContainer,
.priceInfoContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.priceInfoContainer {
  svg {
    margin-right: 1rem;
    min-width: 32px;
    min-height: 32px;
  }
}

.priceLabel {
  color: $neutral-gray;
  @include body-s;
  margin-bottom: 0.5rem;
}

.price {
  color: $white;
  @include body-m;
}

.angle {
  path {
    fill: $neutral-white-alpha-50;
  }
}

.metadataHeader {
  display: flex;
  justify-content: space-between;

  h1 {
    color: $primary-beige;
    @include header-xs;
    margin: 0;
    text-transform: uppercase;
  }
}

.metadataItem {
  align-items: center;
  @include glass-background;
  @include stroke-border;
  backdrop-filter: blur(15px);
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  padding: 1rem;
  position: relative;
  z-index: 2;
}

.stepContainer {
  display: flex;
  flex-direction: column;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;

  button {
    width: calc(50% - 0.5rem);
  }

  @media (max-width: 960px) {
    flex-direction: column;

    button {
      width: 100%;

      &:first-of-type {
        margin-bottom: 1rem;
      }
    }
  }
}

.button {
  margin-top: 1rem;
}

.subtext {
  color: $white;
  @include body-m-bold;
  margin-bottom: 0.5rem;

  @media (max-width: 960px) {
    @include body-s-bold;
  }
}

.subtextLight {
  align-items: center;
  color: $secondary-gray-white;
  display: flex;
  @include body-s;

  svg {
    margin-right: 0.5rem;
    path {
      fill: $secondary-gray-white;
    }
  }
}

.smallButton {
  @include body-xs-bold;
  padding: 0.5rem 1rem;
}

.iconContainer {
  align-items: center;
  @include glass-background;
  @include stroke-border;
  display: flex;
  justify-content: center;
  height: 2.5rem;
  min-height: 2.5rem;
  min-width: 2.5rem;
  margin-right: 1rem;
  width: 2.5rem;

  svg {
    path {
      fill: $secondary-blue;
    }
  }
}

.metadataContainer {
  @include glass-background;
  @include stroke-border;
  color: $white;
  padding: 1rem;
}

.metadataContent {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;

  .subtext {
    margin-bottom: 0;
  }
}

.metadataContentInner {
  display: flex;
  align-items: center;
}

.metadataItemHalf {
  width: calc(50% - 2.5rem);

  @media (max-width: 960px) {
    width: calc(100% - 2rem);
  }
}

.metadataRow {
  display: flex;
  justify-content: space-between;

  @media (max-width: 960px) {
    flex-direction: column;
  }
}

.actionHeader {
  align-items: center;
  display: flex;
  margin-bottom: 0.5rem;

  h1 {
    color: $primary-beige;
    @include header-xs;
    margin: 0;
    margin-right: 1rem;
    text-transform: uppercase;
  }

  @media (max-width: 960px) {
    flex-direction: column-reverse;
    align-items: flex-start;

    h1 {
      margin-top: 1rem;
    }
  }
}

.step {
  display: flex;
  width: 100%;

  &:last-of-type {
    .stepDescription {
      margin-bottom: 0;
    }
  }
}

.rectangle {
  background: $gray-secondary;
  height: calc(100% - 24px);
  margin: 0 auto;
  width: 1px;
}

.progressIndicator {
  margin-right: 1rem;

  svg {
    min-height: 16px;
    min-width: 16px;
    width: 16px;
    height: 16px;
  }
}

.stepHeader {
  color: $neutral-gray;
  @include body-s-bold;
  margin-bottom: 0.5rem;
}

.activeStep {
  color: $secondary-blue;
}

.completedStep {
  align-items: center;
  color: $system-green-green;
  justify-content: flex-start;
  display: flex;

  svg {
    margin-left: 0.5rem;
  }
}

.disabledStep {
  background: $glass-disabled;
}

.stepDescription {
  color: $neutral-gray;
  @include body-xs;
  margin-bottom: 1rem;
}

.activeDescription {
  color: $white;
}

.unsealWarning {
  color: $neutral-gray;
  @include body-xs;
  margin-top: 1rem;
  margin-bottom: 0;
  text-align: center;
}

.actionContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  button {
    width: calc(50% - 0.5rem);
  }

  .oneCtaButton {
    width: 100%;
  }
}

.ticketImage {
  width: 100px;
  height: 100px;
  margin-right: 1rem;

  @media (min-width: 960px) {
    display: none;
  }
}

.metadataInner {
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 960px) {
    flex-direction: column;
    width: 100%;

    button {
      margin-top: 1rem;
    }
  }
}

.ticketStepContainer {
  @include glass-background;
  @include stroke-border;
  margin-top: 1rem;
  padding: 1rem;
  position: relative;
}

.claimSuccess {
  background-image: url(https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/confettiimage.png);
  filter: blur(10px);
  height: 100%;
  left: 0;
  opacity: 0.7;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 4;
}

.claimTextBg {
  background: #1d1b22;
  border: 1px solid #3b3d41;
  filter: blur(20px);
  height: calc(100% - 2rem);
  left: 1rem;
  opacity: 0.5;
  position: absolute;
  top: 1rem;
  width: calc(100% - 2rem);
  z-index: 5;
}

.claim {
  border-radius: 8px;
  left: calc((100% - 348px) / 2);
  position: absolute;
  top: 5rem;
  z-index: 6;
  h1 {
    color: $system-green-green;
    @include body-xl-bold;

    @media (max-width: 960px) {
      text-align: center;
    }
  }

  p {
    color: $white;
    @include body-s;
    text-align: center;
  }
}

.close {
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 6;
}

.unsealLoading {
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: $white;

  h1 {
    color: $primary-beige;
    @include header-s;
    margin: 3rem 0 1rem 0;
    text-align: center;
    text-transform: uppercase;
  }

  p {
    color: $secondary-gray-white;
    @include body-s;
    text-align: center;
    margin: 0;
    margin-bottom: 1rem;
  }

  img {
    border-radius: 0.5rem;
    width: 416px;
    height: 416px;
    margin: 0 auto;

    @media (max-width: 960px) {
      margin-top: 2rem;
      margin-bottom: 5rem;
      width: 100%;
      height: auto;
    }
  }
}
