@import '../../css/global.scss';

.swapModal {
  width:392px;
  padding:30px;
}

.swapTitle{
  color: var(--primary-beige, #FFE9D2);
  font-family: Sequel100Black-85;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 20px */
  text-transform: uppercase;
}

.subTitle{
  margin-bottom:24px;
  margin-top:18x;
  color: var(--neutral-gray, #B8B8B8);
  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  /* Body/Regular/S */
  font-family: Roboto Mono;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal
}

.pill{
  border-radius: var(--spacing-8, 8px);
  background: var(--neutral-input-box, #292929);
  min-height:70px;
  padding:15px;
  box-sizing:border-box;
}

.balancePill{
  display:flex;
  align-items:center;
  justify-content:space-between;
  margin-bottom:12px;
}

.balancePillLeft{
  display:flex;
  align-items:flex-start;
  height:100%;
  justify-content:space-between;
  flex-direction:column;
}

.balancePillFrom{
  color: var(--neutral-gray, #B8B8B8);
  /* Body/Regular/XS */
  font-family: Roboto Mono;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.balancePillAmount{
  color: var(--neutral-gray, #B8B8B8);
  /* Body/Bold/M */
  font-family: Roboto Mono;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}


.balancePillRight{
  display:flex;
  align-items:flex-end;
  height:100%;
  justify-content:space-between;
  flex-direction:column;
}

.usdc{
  display:flex;
  align-items:center;
  color: var(--neutral-white, #FAFAFA);
  /* Body/Bold/S */
  font-family: Roboto Mono;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.balance{
  color: var(--neutral-gray, #B8B8B8);
  font-family: Roboto Mono;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.choosePill{
  display:flex;
  align-items:flex-start;
  justify-content:space-between;
  flex-direction:column;
}

.chooseAmount{
  color: var(--neutral-gray, #B8B8B8);
  /* Body/Regular/XS */
  font-family: Roboto Mono;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom:12px;
}

.amountsContainer{
  display:flex;
  width:100%;
  color: var(--neutral-gray, #B8B8B8);
  /* Body/Bold/S */
  font-family: Roboto Mono;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.amountPill{
  width:33%;
  border-radius: var(--spacing-8, 8px);
  background: var(--glass-background, linear-gradient(137deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.01) 100%), rgba(0, 0, 0, 0.25));
  display:flex;
  align-items:center;
  justify-content:center;
  padding:5px;
  cursor:pointer;
}

.downArrowContainer{
  width:100%;
  text-align:center;
  margin-top:20px;
  margin-bottom:20px;
}

.bottomPill{
  display:flex;
  align-items:center;
  justify-content:space-between;
}

.approveSwap{
  cursor:pointer;
  margin-top:24px;
  display: flex;
  padding: var(--spacing-12, 12px) var(--spacing-32, 32px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-12, 12px);
  flex: 1 0 0;
  border-radius: var(--spacing-8, 8px);
  background: var(--secondary-blue, #19B2E6);
  color: var(--neutral-black, #181A1E);
  text-align: center;
  /* Body/Bold/S */
  font-family: Roboto Mono;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;  
}

.container {
    background: $neutral-black;
    @include stroke-border;
    padding:20px;
}

.content {
    padding: 1rem 1rem 2rem 1rem;
}

.loading {
  box-sizing:border-box;
  display:flex;
  width:100%;
  align-items:center;
  justify-content:center;
  padding:30px;
}

.buttonContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;

    .button {
        width: calc(50% - 0.5rem);
    }

    a {
        text-decoration: none;
    }
}
