@import "../css//global.scss";

.signInModal {
  background: #1d1b22;
  border-radius: 8px;
  padding: 1rem;

  @include stroke-border;

  h1 {
    color: $primary-beige;
    @include header-xs;
    margin: 0;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  p {
    color: $neutral-gray;
    @include body-s;
    margin: 0;
    margin-bottom: 2rem;
  }
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  span {
    font-size: 40px;
  }
  svg {
    cursor: pointer;
    rect {
      fill: rgba(255, 255, 255, 0.15);
    }
  }
}

.modalButton {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;

  svg {
    margin-right: 0.5rem;
  }
}

.modalLink {
  align-items: center;
  color: $secondary-gray-white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  @include body-xs;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  text-decoration: none;

  svg {
    margin-left: 0.5rem;
  }
}

:global(.wallet-adapter-button-trigger) {
  background-color: unset !important;
  background: $glass-background;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
    0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(12.5px);
  border-radius: 8px;
  box-sizing: border-box;
  color: $white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  @include body-s-bold;
  font-size: 13px !important;
  font-family: Roboto Mono !important;
  font-weight: 700 !important;
  height: 41px !important;
  padding: 1rem;
  text-align: center !important;
  text-shadow: 0px 0px 50px 4px rgba(67, 186, 125, 0.5);
  transition: 0.3s all;
  width: 100% !important;
  z-index: 1;

  position: relative;

  &:hover {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 8px;
      border: 2px solid transparent;
      background: $glass-stroke border-box;
      -webkit-mask: linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: destination-out;
      mask-composite: exclude;
      transition: 0.3s all;
    }
  }

  svg {
    margin-right: 0.5rem;
  }

  :global(.wallet-adapter-button-start-icon) {
    display: none;
  }
}

:global(.wallet-adapter-dropdown) {
  display: block !important;
}

.buttonRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;

  svg {
    cursor: pointer;
    rect {
      fill: rgba(255, 255, 255, 0.15);
    }
  }
}

.label {
  align-items: center;
  color: $neutral-gray;
  display: flex;
  @include body-xs;
  margin-bottom: 0.5rem;

  svg {
    margin-right: 0.25rem;
  }
}

.input {
  background: $neutral-input-box;
  color: $neutral-gray;
  @include body-s-bold;
  margin-bottom: 2rem;
  position: relative;
  width: 100%;
  z-index: 1;
}

.verificationInputContainer {
  display: flex;
  justify-content: space-between;
}

.verificationInput {
  box-sizing: border-box;
  font-size: 32px;
  text-align: center;
  @include body-xl-bold;
  width: 104px;
}

.fieldSuccess {
  border: 1px solid $ticketdex-green;
}

.verificationButton {
  margin-top: 1rem;
}
