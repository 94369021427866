@import "../../css/global.scss";

.card {
  background: $glass-background;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
    0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(12.5px);
  border-radius: $s;
  cursor: pointer;
  position: relative;
  &:hover {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 8px;
      border: 2px solid transparent;
      background: $glass-stroke border-box;
      -webkit-mask: linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: destination-out;
      mask-composite: exclude;
      z-index: 1;
      transition: 0.3s all;
    }
  }
}

.cardImg {
  border-radius: 8px 8px 0 0;
  height: 184px;
  object-fit: cover;
  object-position: center;
  width: 100%;
  position: relative;
}

.cardTop {
  position: relative;
  transition: 0.3s all;
}

.cardBottom {
  margin-bottom: 3rem;
  padding: 1rem;

  h3 {
    color: $primary-beige;
    @include body-m-bold;
    margin: 0;
  }

  p {
    color: $secondary-gray-white;
    @include body-s;
    font-weight: 400;
    margin: 0;
    text-align: left;
    text-shadow: 0px 0px 50px 4px $system-green-alpha-50;
    span {
      margin: 0 0.25rem;
      &:first-of-type {
        margin-left: 0;
      }
    }
  }

  @media (max-width: 960px) {
    margin-bottom: 2rem;
  }
}

.priceContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  position: absolute;
  bottom: 1rem;
  width: calc(100% - 2rem);
}

.priceDescription {
  display: flex;
  align-items: center;
  border-radius: 6px;
  margin-top: 0.5rem;
  svg {
    margin: 0 0.25rem;
  }

  span {
    @include body-s-bold;
    color: $system-green-green;
  }
}

.angle {
  height: 12px;
  width: 12px;
  path {
    fill: $neutral-gray;
  }
}

.titleContainer {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;

  svg {
    padding-top: 3px;
    min-width: 12px;
    min-height: 12px;
    margin-left: 0.5rem;
    path {
      fill: $gray-secondary;
    }
  }
}

.dateTag {
  position: absolute;
  left: 1rem;
  bottom: 1rem;

  &:before {
    border: 2px solid transparent;
  }
}

.loader {
  background-image: url(https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/eventcardloader.gif);
  height: 306.5px;
  position: relative;
  top: 4.3rem;
}
