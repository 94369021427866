.pageContainer {
  position: relative;
}

.contentContainer {
  max-width: 1250px;
  margin: 0 auto;
  padding-top: 1rem;
  position: relative;
  z-index: 1;

  @media (max-width: 960px) {
    padding: 1rem;
  }
}

.bgImage {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  object-position: top;
  position: fixed;
  z-index: 0;
}
