@import "../../css/global.scss";

.footerContainer {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
    0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(12.5px);
  background-color: $neutral-black;
  width: 100%;
}

.footerContainerInner {
  display: flex;
  max-width: 1250px;
  margin: 0 auto 3rem auto;
  padding: 1rem;

  @media (max-width: $breakpoint-l) {
    flex-direction: column;
    margin: 0 auto 1rem auto;
  }
}

.footerLogoColumn {
  margin-right: 2rem;
}

.footerLogo {
  height: 9rem;
  width: 8.25rem;

  @media (max-width: $breakpoint-l) {
    margin-left: 1rem;
  }
}

.footerLinkContainer,
.footerForm {
  color: $primary-beige;
  display: flex;
  flex-grow: 2;
  flex-direction: column;
  margin: 1rem 2rem;

  h3 {
    color: $primary-beige;
    @include header-xs;
    margin: 0 0 1.5rem 0;
    text-transform: uppercase;
  }

  a,
  .footerLink {
    box-sizing: border-box;
    @include body-s;
    height: 35px;
    text-decoration: none;
    margin-bottom: 1rem;
    padding: 0.5rem 0;
    transition: 0.3s all;
    width: fit-content;

    &:visited,
    &:link {
      color: inherit;
      text-decoration: none;
    }

    &:hover {
      color: $gray-400;
      border-bottom: 2px solid $gray-400;
    }

    @media (max-width: $breakpoint-l) {
      margin-bottom: 0.5rem;
    }
  }

  .socialLink {
    &:hover {
      border-bottom: unset;
    }
  }
}

.footerForm {
  h3 {
    margin-bottom: 1rem;
  }

  @media (max-width: 980px) {
    margin-top: 1rem;
  }
}

.footerCopyright {
  color: $neutral-white-alpha-50;
  @include body-s;
  text-align: center;
  border-top: 1px solid $glass-stroke;
  max-width: 1250px;
  margin: 0 auto;
  padding: 1rem 0;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background: $glass-stroke;
    top: -1px;
    left: 0;
  }
}

.captain {
  color: $primary-beige;
  @include body-s;
  text-decoration: none;

}
