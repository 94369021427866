@import "../../css/global.scss";

.closeCircle {
  align-items: center;
  background: $glass-background;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
    0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(12.5px);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 28px;
  justify-content: center;
  position: relative;
  width: 28px;
  z-index: 1;

  svg {
    path {
      fill: $white;
    }
  }

  &:hover {
    cursor: pointer;
    &::before {
      content: "";
      position: absolute;
      height: 28px;
      width: 28px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 50%;
      border: 2px solid transparent;
      background: $glass-stroke border-box;
      -webkit-mask: linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: destination-out;
      mask-composite: exclude;
      z-index: 1;
      transition: 0.3s all;
    }
  }
}
