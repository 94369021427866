@import "../css//global.scss";

.homeHeading {
  color: $primary-beige;
  @include display-m;
  margin: 0 auto;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 0px 0px 64px $primary-beige;

  @media (max-width: 960px) {
    @include header-m;
  }
}

.searchContainer {
  @media (min-width: 960px) {
    display: none;
  }
}

.homeHeroContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1rem;
}

.homeHeroLogo {
  height: 56px;
  margin: 0 auto;
  width: 140px;
}

.homeHeroSubheading {
  color: $secondary-gray-white;
  @include body-l-bold;
  margin: 0;
  margin-bottom: 2rem;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 960px) {
    @include body-s-bold;
    margin-bottom: 1rem;
  }
}

.homeHeroSubheadingSmall {
  color: $secondary-gray-white;
  @include body-s-bold;
  margin: 0;
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 960px) {
    @include body-xs;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
}

.homeSubheading {
  background: linear-gradient(249.51deg, #00ffa3 -47.16%, #dc1fff 140.55%);
  background-clip: text;
  font-family: JKR Haas Grotesk Bold;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  margin-top: 3rem;
  text-align: center;
  text-shadow: 0px 0px 50px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.headingLink {
  box-sizing: border-box;
  color: $gray-400;
  font-family: "JKR Haas Grotesk Bold";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  height: 43px;
  line-height: 19px;
  margin: 0 auto;
  padding: 0.5rem;
  text-decoration: unset;
  transition: 0.1s all;

  &:hover {
    border-bottom: 2px solid $gray-400;
  }
}

.homeDiscoveryContainer {
  min-height: 1500px;
  position: relative;

  &::before {
    background-size: contain;
    content: "";
    height: 100%;
    opacity: 0.2;
    position: absolute;
    width: 100%;
  }
}

.homeDiscoveryInner {
  width: 100%;
}

.homeDiscoveryRow {
  max-width: 1100px;
  margin: 0 auto;
  padding: 2rem 1rem 1rem 1rem;
}

.discoverySectionHeader {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  h2 {
    color: $primary-beige;
    @include header-s;
    margin: 0;
    text-transform: uppercase;

    @media (max-width: 960px) {
      @include header-xs;
    }
  }
}

.discoverySectionList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 960px) {
    flex-direction: column;
  }
}

.locationList {
  flex-direction: row;

  .card4w {
    width: calc((100% - 11.5rem) / 4);
    @media (max-width: 960px) {
      width: calc(50% - 2.5rem);
    }
  }
}

.card2w {
  width: calc((100% - 2rem) / 2);

  @media (max-width: 960px) {
    width: 100%;

    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }
  }
}

.card4w {
  width: calc((100% - 4.5rem) / 4);

  @media (max-width: 960px) {
    width: 100%;

    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }
  }
}

.button {
  @include body-xs-bold;
  min-width: fit-content;
}
