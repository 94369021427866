@import "../../css/global.scss";

.dateContainer {
  background: $neutral-dark-gray;
  border-radius: 16px;
  padding: 0.5rem;
  border: 1px solid #404040;
  width: fit-content;
}

:global(.react-datepicker) {
  border: unset;
  color: $white;
  background: $neutral-dark-gray;
  border-radius: 16px;
}

:global(.react-datepicker__current-month),
:global(.react-datepicker__day-name),
:global(.react-datepicker__day) {
  color: $white;
}

:global(.react-datepicker__current-month) {
  @include body-m-bold;
}

:global(.react-datepicker__day-name),
:global(.react-datepicker__day) {
  @include body-l;
  width: 48px;
  line-height: 48px;

  @media (max-width: 960px) {
    width: 40px;
  }
}

:global(.react-datepicker__header) {
  background: $neutral-dark-gray;
  border-bottom: unset;
  border-radius: 16px !important;
}

:global(.react-datepicker__day--disabled),
:global(.react-datepicker__day--outside-month) {
  color: #9e9e9e;
}

:global(.react-datepicker__day-names) {
  border-bottom: 1px solid $white;
}

:global(.react-datepicker__month) {
  margin: 0;
}

:global(.react-datepicker__day--selected) {
  background: $secondary-blue;
  border-radius: 24px;
  color: $neutral-dark-gray;

  &:hover {
    border-radius: 24px;
    background: $secondary-blue;
  }
}

:global(.react-datepicker__day--in-range) {
  background: $secondary-blue;
  border-radius: 0px;
  color: $neutral-dark-gray;

  &:hover {
    border-radius: 0px;
    background: $secondary-blue;
  }
}

:global(.react-datepicker__day--range-start) {
  background: $secondary-blue;
  border-radius: 24px 0px 0px 24px;
  color: $neutral-dark-gray;

  &:hover {
    border-radius: 24px 0px 0px 24px;
    background: $secondary-blue;
  }
}

:global(.react-datepicker__day--range-end),
:global(.react-datepicker__day--in-range .react-datepicker__day--weekend) {
  background: $secondary-blue;
  border-radius: 0px 24px 24px 0px;
  color: $neutral-dark-gray;

  &:hover {
    border-radius: 0px 24px 24px 0px;
    background: $secondary-blue;
  }
}

:global(.react-datepicker__day-in-range),
:global(.react-datepicker__day-in-selecting-range) {
  :nth-of-type(1):nth-last-of-type(1) {
    border-radius: 24px;
  }
}

:global(.react-datepicker__day--in-selecting-range) {
  border-radius: 0px;
  background: $secondary-blue !important;
}

:global(.react-datepicker__day--selecting-range-start) {
  border-radius: 24px 0px 0px 24px;
  background: $secondary-blue;
}

:global(.react-datepicker__day--selecting-range-end) {
  border-radius: 0px 24px 24px 0px;
  background: $secondary-blue;
}

:global(.react-datepicker__day) {
  &:hover {
    background: $secondary-blue;
  }
}
