@import "../css/global.scss";

.accessModal {
  margin-top: 1rem;
}

.container {
  background: $ticketdex-non-opaque-background;
  border-radius: 8px;
  padding: 1rem;
  position: relative;

  @include stroke-border;

  @media (max-width: 460px) {
    overflow: scroll;
    height: calc(100vh - 4rem);
  }
}

.close {
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 1;
}

.icon {
  font-size: 40px;
  text-align: left;
  margin-bottom: 1rem;
  width: 100%;
}

.heading {
  color: $primary-beige;
  @include header-xs;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

.subtext {
  color: $neutral-gray;
  @include body-s;
  margin-bottom: 2rem;
}

.inputContainer {
  display: flex;
  flex-direction: column;

  label {
    color: $neutral-gray;
    @include body-xs;
    margin-bottom: 0.5rem;
  }
}

.optionsContainer {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.select {
  background-color: $neutral-input-box;
  border-radius: 8px;
  border: 1px solid transparent;
  box-sizing: border-box;
  color: $neutral-gray;
  display: flex;
  justify-content: space-between;
  height: 50px;
  @include body-s-bold;
  padding: 1rem;
  position: relative;
  transition: 0.1s all;
  width: 100%;

  &:hover {
    border: 1px solid $white;
  }
}

.selectOptions {
  background-color: $neutral-dark-gray;
  border-radius: 8px;
  position: absolute;
  bottom: 3.25rem;
  width: 100%;
  z-index: 3;
}

.selectItem {
  color: $white;
  display: flex;
  @include body-s;
  justify-content: space-between;
  padding: 1rem;

  &:hover {
    background: #292929;
  }
}

.selected {
  color: $secondary-blue;
}

.button {
  margin-top: 1rem;
}
