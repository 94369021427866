@import "../css/global.scss";

.eventInfoContainer {
  @include glass-background;
  @include stroke-border;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  padding: 1rem;

  @media (max-width: 960px) {
    flex-direction: column;
    margin-bottom: 1rem;
  }
}

.ticketListContainer {
  width: 40%;

  @media (max-width: 960px) {
    width: 100%;
  }
}

.eventPageContainer {
  display: flex;
  max-width: 1250px;
  margin: 2rem auto;
}

.info {
  padding: 0.5rem 0.75rem;
}

.date {
  color: $secondary-blue;
  @include body-s-bold;
  text-transform: uppercase;
}

.eventInfo {
  h1 {
    color: $primary-beige;
    @include body-xl-bold;
    margin: 0.5rem 0;
  }
}

.eventInfoMobileWrapper {
  display: flex;
}

.mobileVenueImageContainer {
  display: none;
  @media (max-width: 960px) {
    @include glass-background;
    @include stroke-border;
    display: flex;
    margin-top: 1rem;
    padding: 1rem;
    width: calc(100% - 2rem);
  }
}

.venue {
  color: $secondary-gray-white;
  display: flex;
  @include body-s;

  svg {
    min-width: 1.125rem;
    min-height: 1.125rem;
    margin-right: 0.5rem;
    path {
      fill: $secondary-gray-white;
    }
  }
}

.select {
  @include glass-background;
  border-radius: 8px;
  border: 1px solid transparent;
  box-sizing: border-box;
  color: $white;
  display: flex;
  height: 50px;
  justify-content: space-between;
  @include body-s-bold;
  padding: 1rem 1rem 1rem 2.5rem;
  position: relative;
  transition: 0.1s all;
  width: 100%;

  &:hover {
    border: 1px solid $white;
  }
}
.iconLeft {
  position: absolute;
  top: 30%;
  left: 1rem;
}

.optionsContainer {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.selectOptions {
  background-color: $neutral-dark-gray;
  border-radius: 8px;
  position: absolute;
  top: 3.25rem;
  width: 100%;
  z-index: 3;
}

.selectItem {
  color: $white;
  display: flex;
  @include body-s;
  justify-content: space-between;
  padding: 1rem;

  &:hover {
    background: #292929;
  }
}

.selected {
  color: $secondary-blue;
}

.filters {
  display: flex;
  justify-content: space-between;
}

.ticketInfoListItem {
  align-items: flex-start;
  border-bottom: 1px solid #3b3d41;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 0.5rem 1rem;
  position: relative;
  z-index: 2;

  &:hover {
    background: $glass-background;
  }

  &:last-of-type {
    border-bottom: none;
    border-radius: 8px 8px 0 0;
  }
}

.listHeader {
  @include body-s-bold;
  border-bottom: 1px solid #3b3d41;
  color: $white;
  padding: 0.5rem;
  text-align: center;
}

.ticketGroupList {
  @include glass-background;
  @include stroke-border;
  margin-top: 1rem;

  @media (max-width: 460px) {
    max-height: 100%;
  }
}

.ticketInfo {
  color: $white;
}

.ticketQuantity {
  align-items: center;
  color: $white;
  display: flex;
  @include body-s;
  margin-bottom: 0.5rem;

  svg {
    margin-right: 0.5rem;
    path {
      fill: $white;
    }
  }
}

.ticketType {
  color: $white;
  @include body-m-bold;
  margin-bottom: 0.5rem;
}

.priceDescription {
  display: flex;
  align-items: center;
  background: $glass-background;
  border-radius: 4px;
  box-sizing: border-box;
  color: $system-green-green;
  @include body-s-bold;
  padding: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  transition: 0.3s all;

  @include stroke-border;

  svg {
    margin: 0 0.25rem;
    width: 18px;
    height: 18px;
  }

  span {
    @include body-s-bold;
    margin-right: 0.25rem;
  }
}

.ticketActions {
  align-items: center;
  color: $neutral-gray;
  display: flex;
  @include body-s;
}

.quantitySelector {
  position: relative;
  .select {
    width: unset;
    padding-left: 1rem;
    padding-right: 3rem;
    margin-right: 1rem;
  }
}

.imageContainer {
  align-items: center;
  @include glass-background;
  @include stroke-border;
  display: flex;
  height: fit-content;
  margin-left: 2rem;
  padding: 1rem;

  @media (max-width: 960px) {
    display: none;
  }
}

.venueImage {
  height: 36rem;
  z-index: 2;

  @media (max-width: 960px) {
    height: auto;
    width: 100%;
  }
}
