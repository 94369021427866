@import "../../css/global.scss";

.cardDateTag {
  backdrop-filter: blur(50px);
  border-radius: 0px;
  border: 1px solid $primary-beige;
  backdrop-filter: blur(50px);
  border-radius: 8px;
  color: $primary-beige;
  height: fit-content;
  @include body-xs-bold;
  text-shadow: 0px 0px 50px rgba(67, 186, 125, 0.5);
  max-width: fit-content;
}

.month {
  @include body-xs-bold;
  text-transform: uppercase;
  padding: 0.25rem 1rem;
}

.day {
  color: $primary-beige;
  border-top: 1px solid $primary-beige;
  @include body-l-bold;
  text-align: center;
  width: 100%;
}
