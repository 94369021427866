@import "../css/global.scss";

.pageContainer {
  max-width: 1100px;
  margin: 0 auto;
}

.header {
  h1 {
    color: $primary-beige;
    @include header-s;

    @media (max-width: 960px) {
      @include header-xs;
      text-transform: uppercase;
    }
  }
}

.buttonHeaderRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;


  @media (max-width: 960px) {
    flex-direction: column;
    margin-bottom: 1rem;
  }
}

.filterButtonContainer {
  display: flex;

  button {
    align-items: center;
    display: flex;
    width: fit-content;
    margin-right: 1rem;

    svg {
      margin-right: 0.5rem;
    }
  }

  @media (max-width: 960px) {
    margin-bottom: 1rem;
  }
}

.noUpcoming {
  align-items: center;
  @include glass-background;
  @include stroke-border;
  border-radius: 8px;
  color: $secondary-gray-white;
  display: flex;
  @include body-s;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 2rem;
  padding: 8rem 1rem;

  a {
    margin-top: 1rem;
    text-decoration: none;
  }
}

.iconContainer {
  align-items: center;
  @include glass-background;
  @include stroke-border;
  display: flex;
  justify-content: center;
  height: 64px;
  min-height: 64px;
  min-width: 64px;
  width: 64px;
}

.nftContainer {
  margin: 0 auto;
  width: 1100px;
  min-height: 100vh;

  @media (max-width: 960px) {
    width: unset;
  }
}

.loadingSubtext {
  @include body-s;
  text-align: center;
  color: $white;
}

.loadingContainer {
  color: $white;
  text-align: center;
  padding: 10rem 10rem 20rem 10rem;

  @media (max-width: 960px) {
    padding: 15rem 0;
  }
}

.spinner {
  width: 40px;
  height: 40px;
  margin: auto;
}
