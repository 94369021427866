@import "../../css/global.scss";

.search {
  background: $glass-background;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
    0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(12.5px);
  border-radius: 8px;
  color: $white;
  border: unset;
  @include body-s;
  padding: 0.75rem 0.75rem 0.75rem calc(1rem + 21px);
  margin-left: -30px;
  width: calc(100% - 1rem);

  &::placeholder {
    color: $white;
  }

  &:focus {
    outline: none;
  }
}

.searchContainer {
  --angle: 0deg;
  align-items: center;

  border-radius: 8px;
  display: flex;
  position: relative;
  margin: 0 auto;
  padding: 2px;
  width: 430px;
  z-index: 1;
  svg {
    margin-left: 0.5rem;
    z-index: 1;
    path {
      fill: $white;
    }
  }

  @media (max-width: 960px) {
    display: none;
  }
}

.isMobile {
  display: flex;
  width: 100%;
  @media (min-width: 960px) {
    display: none;
  }

  .autocomplete {
    width: 100%;
  }
}

.autocomplete {
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 8px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
    0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(12.5px);
  position: absolute;
  top: 3rem;
  width: 430px;
}

.autocompleteItem {
  background-color: $glass-background;
  display: flex;
  padding: 1rem;
  transition: 0.1s all;

  &:hover {
    background: $glass-stroke;

    &:first-of-type {
      border-radius: 8px 8px 0 0;
    }
    &:last-of-type {
      border-radius: 0 0 8px 8px;
    }
  }
}

.autocompleteIconContainer {
  @include glass-background;
  @include stroke-border;
  align-items: center;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  margin-right: 1rem;
  height: 2.5rem;
  width: 2.5rem;
  margin: auto 1rem auto 0;
  svg {
    width: 1rem;
    height: 1rem;
    min-width: 1rem;
    min-height: 1rem;
    margin: auto;
    path {
      fill: $secondary-blue;
    }
  }
}

.autocompleteItemLabel {
  color: $neutral-gray;
  display: block;
  @include body-s;
}

.autocompleteItemTitle {
  color: $white;
  @include body-m-bold;
  margin: 0;
}
