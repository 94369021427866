@import "../css/global.scss";

.nftListItem {
  background: $glass-background;
  backdrop-filter: blur(50px);
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  margin-bottom: 1.5rem;

  @include stroke-border;

  &:hover {
    &:before {
      border: 2px solid transparent;
      background: $neutral-gray;
    }
  }

  @media (max-width: 960px) {
    flex-direction: column-reverse;
  }
}

.nftImage {
  border: 1px solid #292929;
  border-radius: 4px;
  height: 120px;
  width: 188px;
  object-fit: cover;
  margin-right: 1rem;

  @media (max-width: 960px) {
    display: none;
  }
}

.nftInfo {
  align-items: center;
  display: flex;

  @media (max-width: 960px) {
    flex-direction: column;
  }
}

.nftDate {
  color: $secondary-blue;
  @include body-s-bold;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
}

.nftTitle {
  color: $primary-beige;
  @include body-l-bold;
  margin-bottom: 0.5rem;
}

.nftVenue {
  align-items: center;
  color: $secondary-gray-white;
  display: flex;
  @include body-s;
  svg {
    margin-right: 0.5rem;
    path {
      fill: $secondary-gray-white;
    }
  }
}

.ticketQuantity {
  align-items: center;
  border-radius: 4px;
  display: flex;
  height: fit-content;
  margin-left: 1rem;
  padding: 0.5rem 1rem;
  svg {
    margin-right: 0.5rem;
  }
}

.nftStatus {
  align-items: center;
  display: flex;

  @media (max-width: 960px) {
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
}
