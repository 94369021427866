@import '../css/global.scss';

.videoContainer {
    width: 100vw;
    position: absolute;
    top: -77px;
    padding-top: 77px;
    overflow: hidden;

    @media (max-width: 960px) {
        height: 65vh;
        margin-left: -1rem;

        video {
            min-width: 100%; 
            min-height: 100%; 
        
            max-width: 80vw;
            position: absolute;
            top: 60vh;
            left: 50%;
            object-fit: cover;
            transform: translate(-50%,-50%);
      }
    }


}

.blackBackground {
    background-color: #101010;
    background-image: unset;

    :global(#backgroundImage) {
        display: none;
    }
}

.hideVideo {
    display: none;
}

.contentContainer {
    display: flex;
    justify-content: center;
    min-height: 90vh;
    padding-top: 3rem;

    @media (max-width: 960px) {
        flex-direction: column;
        padding-top: 0;
    }
}

.imageContainer {
    @include stroke-border;
    margin-right: 1rem;
    width: 24rem;
    height: 24rem;

    @media (max-width: 960px) {
        width: 50%;
        height: 100%;
        margin: 0 auto;
    }
}

.image {
    display: block !important;
    width: 100%;
    height: 100%;
}

.content {
    background: $neutral-black;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    @include stroke-border;
    backdrop-filter: blur(12.5px);
    height: fit-content;
    padding: 2rem 1rem;
    width: 24rem;

    a {
        text-decoration: none;
    }

    @media (max-width: 960px) {
        width: calc(100% - 2rem);
        height: 100%;
        margin-top: 1rem;
    }


    .green {
        color: $system-green-green;
        @include header-m;
        text-transform: uppercase;
        text-align: center;
    }

    .rewardContainer {
        @include glass-background;
        @include stroke-border;
        padding: 1rem;

        h1 {
            color: $primary-beige;
            @include header-xl;
            margin: 0;
            text-transform: uppercase;
            text-align: center;
        }

        p {
            color: $primary-beige;
            @include body-s;
            margin-bottom: 0;
            text-align: center;
        }
    }

    .rewardDescription {
        @include body-s;
        color: $neutral-gray;
        margin: 2rem auto;
    }
}
