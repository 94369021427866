@import "../css/global.scss";

.eventListHeader {
  max-width: 1250px;
  margin: 0 auto;
}

.eventListHeroContainer {
  display: flex;
  margin: 1rem 0;
  img {
    border-radius: 8px;
    margin-right: 1rem;
    width: 5rem;
    height: 6rem;
    z-index: 1;

    @media (max-width: 960px) {
      display: none;
    }
  }
}

.filterOption {
  color: $white;
  @include body-s-bold;
}

.tagContainer {
  width: 100%;
}

.eventTagContainer {
  width: 100%;
}

.tagRow {
  display: flex;
  margin-bottom: 1rem;
  width: 100%;

  button {
    margin-right: 0.5rem;
  }

  @media (max-width: 960px) {
    position: relative;

    &:first-of-type {
      margin-bottom: 1.5rem;
    }
  }
}

.concertRow {
  overflow: scroll;
  width: fit-content;
  max-width: calc(100% + 1rem);

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.filterOption {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: 35px;
  padding: 0.5rem;
  margin: 0 0.5rem;

  svg {
    margin-left: 0.5rem;
  }

  &:hover {
    color: $gray-400;
    border-bottom: 2px solid $gray-400;
  }
}

.selectedFilterOption {
  &:hover {
    color: #f5f6f6;
    border: unset;
  }

  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background: $ticketdex-gradient;
    bottom: 0px;
    left: 0;
  }
  //   }
}

.button {
  @include body-s-bold;
}

.close {
  z-index: 2;
}

// make this a mixin

.selected {
  &::before,
  &:hover::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 12px;
    border: 2px solid transparent;
    background: $white border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
    transition: 0.3s all;
  }
}

.unselected {
  &::before {
    border: unset;
  }

  &:hover::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 12px;
    border: 2px solid transparent;
    background: $glass-stroke border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
    transition: 0.3s all;
  }
}

.secondaryFilterSelected {
  text-transform: capitalize;
  &:before,
  &:hover::before {
    border: 1px solid transparent;
  }
}

.secondaryFilterUnselected {
  text-transform: capitalize;

  &:hover::before {
    border: 1px solid transparent;
  }
}

.eventListBody {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1rem;

  h2 {
    color: $primary-beige;
    @include header-s;
    margin: 0;

    .headerGray {
      color: $secondary-gray-white;
      display: block;
      text-transform: uppercase;

      @media (max-width: 960px) {
        margin-bottom: 0.5rem;

        &:last-of-type {
          margin-top: 0.5rem;
        }
      }
    }

    @media (max-width: 960px) {
      @include header-xs;
      margin-bottom: 0.5rem;
      text-transform: uppercase;
    }
  }

  :global(.loading) {
    position: relative;
    width: 40px;
    height: 40px;
    padding: 10rem;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
.lowercase {
  text-transform: lowercase;
}

.locationList {
  border-radius: 12px;
  background-color: #404040;
  padding: unset;
  position: absolute;
  top: 1.5rem;
  width: 280px;
  z-index: 2;

  li {
    align-items: center;
    color: $white;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    @include body-s;
    list-style: none;
    padding: 1rem;

    &:hover {
      background-color: #292929;
    }
  }

  .selectedLocation {
    color: $secondary-blue;
  }
}

.menuContainer {
  position: relative;

  @media (max-width: 960px) {
    position: unset;
  }
}

.card4w {
  width: calc((100% - 4.5rem) / 4);

  @media (max-width: 960px) {
    width: 100%;
  }
}

.eventContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  max-width: 1250px;
  margin: 1rem auto;
  width: 100%;
}

.eventListCard {
  margin-bottom: 2rem;
  margin-right: 1rem;
  padding-bottom: 1rem;

  &:nth-of-type(4n) {
    margin-right: 0;
  }

  @media (max-width: 960px) {
    margin-right: 0;
  }
}

.datePickerContainer {
  position: absolute;
  z-index: 2;
  top: 2.5rem;

  @media (max-width: 960px) {
    left: 0;
    top: 3rem;
  }
}

.bodyHeader {
  align-items: baseline;
  display: flex;
  justify-content: space-between;
  max-width: 1250px;
  margin: 0 auto;
  width: 100%;
  z-index: 1;

  @media (max-width: 960px) {
    flex-direction: column-reverse;

    button {
      margin-top: -0.5rem;
      margin-bottom: 1rem;
    }
  }
}

.clear {
  @include body-xs-bold;
}

.showMoreButton {
  margin-top: 3rem;
  margin-bottom: 5rem;
  width: auto;
}
