@import "../css/global.scss";

.modalContainer {
  background: $ticketdex-non-opaque-background;
  border-radius: 8px;
  padding: 1rem;

  @include stroke-border;
}

.close {
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 1;
}

.icon {
  font-size: 40px;
  margin-bottom: 1rem;
  text-align: left;
  width: 100%;
}

.warning {
  color: $primary-beige;
  @include header-xs;
  margin-bottom: 0.5rem;
}

.subtext {
  color: $neutral-gray;
  @include body-s;
  margin-bottom: 1rem;
}

.listContainer {
  background: $neutral-input-box;
  border-radius: 8px;
  margin-bottom: 1rem;
  padding: 1rem 1rem 0.5rem 1rem;
  width: calc(100% - 2rem);

  @include stroke-border;
}

.listItem {
  align-items: center;
  color: $white;
  display: flex;
  @include body-s;
  margin-bottom: 1.5rem;

  &:last-of-type {
    margin-bottom: 1rem;
  }
}

.inputLabel {
  color: $neutral-gray;
  @include body-xs;
  margin-bottom: 0.5rem;
}

.input {
  background: $neutral-input-box;
  border: 1px solid transparent;
  border-radius: 10px;
  color: $white;
  @include body-s-bold;
  margin-bottom: 1rem;
  padding: 1rem 2.5rem 1rem 0.5rem;
  width: 100%;
  @include stroke-border;
  z-index: 1;

  &:focus {
    border: 1px solid $white;
    outline: unset;
  }
}

.validEmail {
  border: 1px solid $system-green-green;
}
