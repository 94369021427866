@import "../css/global.scss";

.container {
  color: $white;
  @include glass-background;
  @include stroke-border;

  margin-bottom: 1rem;
  padding: 1rem;
}

.pageContainer {
  display: flex;
  max-width: 1250px;
  margin: 0 auto;
  position: relative;

  @media (max-width: 960px) {
    flex-direction: column;
  }
}

.leftColumn {
  margin-right: 1rem;
  width: 392px;

  @media (max-width: 960px) {
    width: 100%;
  }
}

.showInfo {
  padding: 1rem;
}

.showInfoHeader {
  display: flex;
  justify-content: space-between;

  button {
    margin-left: 1rem;
  }
}

.date {
  color: $secondary-blue;
  @include body-s-bold;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
}

.title {
  color: $primary-beige;
  @include body-xl-bold;
  margin-bottom: 0.5rem;
}

.venue {
  align-items: center;
  color: $secondary-gray-white;
  display: flex;
  @include body-s;
  margin-bottom: 1rem;
  svg {
    height: 18px;
    margin-right: 0.5rem;
    min-height: 18px;
    min-width: 18px;
    width: 18px;
    path {
      fill: $secondary-gray-white;
    }
  }

  @media (max-width: 960px) {
    margin-bottom: 0;
  }
}

.info {
  padding: 0.5rem 0.75rem;
}

.showImageContainer {
  @include glass-background;
  padding: 1rem;
  position: relative;

  @media (max-width: 960px) {
    display: none;
  }
}

.showImage {
  width: 100%;
}

.venueImage {
  background: #1d1b22;
  border: 1px solid #3b3d41;
  backdrop-filter: blur(12.3865px);
  border-radius: 6px;
  max-width: 77px;
  max-height: 58px;
  position: absolute;
  bottom: 1rem;
  left: 1rem;
}

.seatInfo {
  color: $white;
  font-family: "JKR Haas Grotesk Bold";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 0.5rem;
}

.ticketQuantity {
  align-items: center;
  color: $gray-secondary;
  display: flex;
  font-family: "JKR Haas Grotesk Bold";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  svg {
    margin-right: 0.5rem;
    path {
      fill: $gray-secondary;
    }
  }
}

.ticketInfoItem {
  display: flex;
  margin-bottom: 1rem;
  svg {
    height: 18px;
    margin-right: 1rem;
    min-width: 18px;
    min-height: 18px;
    width: 18px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.ticketInfoItemHeader {
  color: $secondary-gray-white;
  @include body-s-bold;
  margin-bottom: 0.5rem;
}

.ticketInfoItemDescription {
  color: $neutral-gray;
  @include body-s;
}

.checkoutContainer {
  height: fit-content;
  width: calc(100% - (392px + 1rem));
  h1 {
    color: $primary-beige;
    @include header-xs;
    margin: 0;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
  }

  p {
    color: $white;
    @include body-s;
    margin: 0;
  }

  @media (max-width: 960px) {
    width: calc(100% - 2rem);
  }
}

.ticketCount {
  @media (max-width: 960px) {
    flex-direction: column;
  }
}

.metadataItem {
  align-items: center;
  @include glass-background;
  backdrop-filter: blur(20px);
  cursor: pointer;

  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 1rem;
}

.angle {
  path {
    fill: $neutral-white-alpha-50;
  }
}

.iconContainer {
  align-items: center;
  @include glass-background;
  @include stroke-border;
  backdrop-filter: blur(20px);
  display: flex;
  justify-content: center;
  height: 2.5rem;
  min-height: 2.5rem;
  min-width: 2.5rem;
  margin-right: 1rem;
  width: 2.5rem;

  svg {
    width: 1rem;
    height: 1rem;
  }
}

.metadataInfo {
  display: flex;
}

.metadataItemHeader {
  color: $neutral-gray;
  @include body-s;
  margin-bottom: 0.5rem;
}

.metadataItemValue {
  align-items: center;
  color: $white;
  @include body-s-bold;
  display: flex;

  svg {
    margin-right: 0.5rem;
  }
}

.metadataLarge {
  @include body-m-bold;
}

.ticketImage {
  border: 1px solid #3b3d41;
  border-radius: 6px;
  height: 100px;
  margin-right: 1rem;
  width: 100px;
}

.metadataWithMargin {
  margin-bottom: 0.5rem;
}

.metadataItemDetail {
  color: $secondary-gray-white;
  @include body-s-bold;
}

.metadataTicketCount {
  align-items: center;
  display: flex;
  margin-left: 1rem;

  svg {
    margin-right: 0.5rem;
    path {
      fill: $secondary-gray-white;
    }
  }

  @media (max-width: 960px) {
    margin-left: 2rem;
  }
}

.metadataColumn {
  display: flex;
  flex-direction: column;
}
.metadataContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.metadataRow {
  display: flex;
  justify-content: space-between;
  padding: 0 0 1rem 0;
  width: 100%;

  &:last-of-type {
    border-top: 1px solid $neutral-black-alpha-30;
    padding-bottom: 0;
    padding-top: 1rem;
  }
}

.disclaimer {
  color: $secondary-gray-white;
  @include body-xs;
  margin-top: 1rem;
  text-align: center;

  a {
    color: $secondary-gray-white;
  }
}

.successContainer {
  border: 1px solid $system-green-green;
  border-radius: 8px;

  h1 {
    color: $system-green-green;
  }
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  svg {
    height: 36px;
    width: 36px;
  }
}

.buttonContainer {
  display: flex;
  justify-content: space-between;

  a {
    width: calc(50% - 0.5rem);
    text-decoration: none;
  }
}

.interstitialContainer {
  height: 80vh;
  width: 100vw;
  z-index: 10;

  @media (max-width: 960px) {
    height: fit-content;
    min-height: 100vh;
    width: 100%;
  }
}

.interstitialContent {
  @include glass-background;
  display: flex;
  margin-top: 8rem;

  @media (max-width: 960px) {
    flex-direction: column;
    margin-top: 2rem;
  }

  h1 {
    color: $primary-beige;
    @include header-xs;
    text-transform: uppercase;
  }
  img {
    border-radius: 8px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
      0px 4px 8px 3px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(12.5px);
    width: 31rem;

    @media (max-width: 960px) {
      width: 100%;
    }
  }

  .interstitialText {
    padding: 1rem;
    width: 100%;

    @media (max-width: 960px) {
      width: calc(100% - 2rem);
    }
  }

  .interstitialItem {
    @include glass-background;
    @include stroke-border;
    padding: 1rem;
    margin-bottom: 1rem;
    width: calc(100% - 2rem);
  }

  .interstitialLabel {
    @include body-s-bold;
    color: $neutral-gray;
    margin-bottom: 0.5rem;
  }

  .interstitialLabelActive {
    color: $secondary-blue;
  }

  .interstitialLabelComplete {
    color: $system-green-green;
  }

  .interstitialDescription {
    @include body-s;
    color: $neutral-gray;
  }

  .interstitialDescriptionActive {
    color: $white;
  }
}

.selectContainer {
  align-self: flex-end;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 30%;

  @media (max-width: 960px) {
    width: 50%;
  }
}

.select {
  @include glass-background;
  border-radius: 8px;
  border: 1px solid transparent;
  box-sizing: border-box;
  color: $white;
  height: 50px;
  @include body-s-bold;
  padding: 1rem 1rem 1rem 2.5rem;
  position: relative;
  transition: 0.1s all;
  width: 100%;

  &:hover {
    border: 1px solid $white;
  }
}

.selectOptions {
  background-color: $neutral-dark-gray;
  border-radius: 8px;
  position: absolute;
  right: 100%;
  top: 0rem;
  width: 100%;
  z-index: 3;
}

.selectItem {
  color: $white;
  display: flex;
  @include body-s;
  justify-content: space-between;
  padding: 1rem;

  &:hover {
    background: #292929;
  }
}

.checkoutButton {
  margin-top: 3rem;
}

.iconRight {
  position: absolute;
  right: 1rem;
  top: 37%;
}
