@import "../css/global.scss";

.artistPageContainer {
  display: flex;
  max-width: 1000px;
  margin: 0 auto;

  @media (max-width: 960px) {
    flex-direction: column;
  }
}

.artistDescriptionContainer {
  @include glass-background;
  @include stroke-border;
  border-radius: 8px;
  color: $white;
  height: fit-content;
  margin-right: 1rem;
  position: relative;
  width: 392px;

  @media (max-width: 960px) {
    margin-right: 0;
    margin-bottom: 1rem;
    width: 100%;
  }

  .centerCropped {
    border-radius: 8px 8px 0 0;
    width: 100%;
    height: 12.5rem;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.close {
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.artistInfoContainer {
  padding: 1rem;

  h1 {
    color: $primary-beige;
    @include body-xl-bold;
    margin: 0;
  }

  p {
    border-bottom: 1px solid #3b3d41;
    color: $gray-secondary;
    font-family: "JKR Haas Grotesk";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    padding-bottom: 1rem;
  }

  span {
    color: $ticketdex-purple;
    cursor: pointer;
    font-family: "JKR Haas Grotesk Bold";
  }
}

.linkContainer {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0 0 0;
  border-top: 1px solid $neutral-white-alpha-30;
  margin-top: 1rem;
  a {
    width: calc(50% - 0.5rem);

    button {
      width: 100%;
    }

    span {
      margin: 0;
    }

    svg {
      width: 18px;
      height: 18px;
    }
  }
}

.artistEventList {
  display: flex;
  flex-direction: column;
  width: 100%;
  h2 {
    color: $primary-beige;
    @include header-xs;
    text-transform: uppercase;
  }

  h1 {
    color: $primary-beige;
    @include header-xs;
    text-transform: uppercase;

    span {
      color: $secondary-gray-white;
    }
  }
}

.priceDescription {
  display: flex;
  align-items: center;
  color: $white;
  @include body-s-bold;
  padding-top: 0.5rem;
  margin: 0;
  svg {
    margin: 0 0.25rem;
  }

  span {
    color: $system-green-green;
  }
}

.eventListItem {
  @include glass-background;
  color: $white;
  cursor: pointer;
  display: flex;
  margin-bottom: 1rem;
  padding: 1rem;
  position: relative;
  text-decoration: unset;
  width: 100%;

  @media (max-width: 960px) {
    align-items: center;
    width: calc(100% - 2rem);
  }

  h3 {
    color: $primary-beige;
    @include body-m-bold;
    margin: 0;
    margin-bottom: 0.5rem;
  }

  .venueInfo {
    align-items: center;
    color: $secondary-gray-white;
    display: flex;

    svg {
      margin-right: 0.5rem;
      path {
        fill: $secondary-gray-white;
      }
    }
  }

  .venue {
    color: $secondary-gray-white;
    @include body-s;

    margin: 0;
  }

  .eventInfo {
    display: flex;
    justify-content: space-between;
    margin-left: 1rem;
    width: 100%;

    @media (max-width: 960px) {
      flex-direction: column;
    }
  }

  &:hover {
    background: $glass-stroke;
    border-radius: 8px;
    transition: 0.1s all;
  }
}

.eventRecommendations {
  color: $primary-beige;
  max-width: 1000px;
  margin: 3rem auto;

  h2 {
    @include header-xs;
    text-transform: uppercase;
  }
}

.card3w {
  width: calc((100% - 3rem) / 3);

  @media (max-width: 960px) {
    width: 100%;
    margin-bottom: 1rem;
  }
}

.recommendationsContainer {
  display: flex;
  justify-content: space-between;

  @media (max-width: 960px) {
    flex-direction: column;
  }
}

.showMoreButton {
  margin: 2rem auto 0 auto;
}

.loader {
  border-radius: $s;
  @include stroke-border;
  background-image: url(https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/eventcardloader.gif);
  height: 306.5px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
    0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(12.5px);
}
