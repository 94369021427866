.card-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wrapper {
  position: relative;
  top: 0;
  margin-bottom: -3rem;
}

.in-carousel {
  display: inline-block;
  margin-right: 1rem;
  width: 25.5rem;
}

.scroll {
  padding: 5px;
  height: 235px;
  line-height: 235px;
  position: absolute;
  flex-basis: 0;
  z-index: 5;
  background-color: rgba(225, 225, 225, 0.8);
  color: darkgrey;
  display: none;
  transition: 2s;
}

.card-container:hover .scroll {
  display: block;
  transition: 2s;
}

.scroll-prev {
  left: 10px;
}

.scroll-next {
  right: 10px;
}

.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
}
